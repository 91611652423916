<template>
    <div class="up_main_header">
        <div class="up_main_contianer_full">
            <div class="up_left_block">
                <div :class="['mobi_menu_toggler', customerDropdownShow.showMobileMenu ? 'iconActive':'']" @click="mobileMenu()"> <!-- 'mobi_menu_toggler': -->
                    <i class="pi pi-bars"></i>
                    <i class="pi pi-times"></i>
                </div>
                <div class="up_logo_wrap">
                    <router-link to="/customer/dashboard" class="up_logo">
                        <img :src="imageUrlFrontEnd + 'dist/img/white-logo.png'" class="truabilities-image" alt="Truabilities Image">
                    </router-link>
                </div>
                <div :class="['mobile_menu_overlay', customerDropdownShow.showMobileMenu ? 'overlayActive':'']"></div>
                <nav v-if="isDashboardNow" :class="['up_main_nav up_navbar-nav', customerDropdownShow.showMobileMenu ? 'mobileMenuActive':'']">
                    <li class="up_dropdown up_user up_user-menu"  @click="mobileMenu()">
                        <router-link to="/customer/add-website" >
                            <img :src="imageUrlFrontEnd + 'dist/img/addnewsite-icon.png'" alt=""> Add New
                        </router-link>
                    <!-- <ul class="dropdown-menu">
                        <li>
                        <a href="https://console.staging.truabilities.com/customer/addNewWebsite">ADA Widget</a>
                        </li>
                        <li>
                        <a href="https://console.staging.truabilities.com/customer/additional-services">Additional Service</a>
                        </li>
                        
                    </ul> -->
                    </li>
                    <li  @click="mobileMenu()">
                        <router-link to="/customer/website-listing">
                            <img :src="imageUrlFrontEnd + 'dist/img/mexsisting-icon.png'" alt=""> Manage Existing Sites
                        </router-link>
                    </li>
                    <li  @click="mobileMenu()">
                        <router-link to="/customer/branding-listing">
                            <img :src="imageUrlFrontEnd + 'dist/img/managebranding-icon.png'" alt=""> Manage Branding
                        </router-link>
                    </li>
                    <li  @click="mobileMenu()">
                        <router-link to="/customer/website-logs">
                            <img :src="imageUrlFrontEnd + 'dist/img/supportvedio-icon.png'" alt=""> Scan Logs
                        </router-link>
                    </li>
                    <li class="mibile_menu_active"  @click="mobileMenu()">
                        <router-link to="monitoring">
                            <img :src="imageUrlFrontEnd + 'dist/img/research.png'" alt=""> Monitor
                        </router-link>
                    </li>
                    <li class="mibile_menu_active"  @click="mobileMenu()">
                        <router-link to="billing-details">
                            <img :src="imageUrlFrontEnd + 'dist/img/billing-icon.png'" alt=""> Billing
                        </router-link>
                    </li>
                    <li class="mibile_menu_active"  @click="mobileMenu()">
                        <router-link to="invoice-list">
                            <img :src="imageUrlFrontEnd + 'dist/img/invoice-icon.png'" alt=""> Invoices
                        </router-link>
                    </li>
                     
                </nav>
            </div>
            <div class="up_right_block">
                <nav class="up_right_nav">
                    <li class="desktop_menu_active">
                        <router-link to="monitoring">
                            <img :src="imageUrlFrontEnd + 'dist/img/research.png'" alt=""> Monitor
                        </router-link>
                    </li>
                    <li class="desktop_menu_active">
                        <router-link to="billing-details">
                            <img :src="imageUrlFrontEnd + 'dist/img/billing-icon.png'" alt=""> Billing
                        </router-link>
                    </li>
                    <li class="desktop_menu_active">
                        <router-link to="invoice-list">
                            <img :src="imageUrlFrontEnd + 'dist/img/invoice-icon.png'" alt=""> Invoices
                        </router-link>
                    </li>
                    <li class="user-header-action notification_icon">
                        <a href="#" class="up_bell_icon" @click="customerDropdownToggle('notification')">
                            <img :src="imageUrlFrontEnd + 'dist/img/white-notification.png'" alt="Notification">
                            <span v-if="notification.unread > 0" class="notification_count">{{notification.unread}}</span>
                        </a>
                        <div :class="['up_notification_box', customerDropdownShow.notification ? 'up_notification_box_open' : '']">
                            <h2 >Notifications</h2>
                            <perfect-scrollbar>
                            <ul v-if="notification.count === 0" class="up_notification_list">
                                <li>No notification yet.</li>
                            </ul>

                            <ul v-else class="up_notification_list">
                                <li v-for="(list, i) of notification.data" :key="i" >
                                    <div :class="[list.isRead?'read_notification_item':'unread_notification_item']">
                                        <div :class="[list.isRead?'notification_text_read':'notification_text_unread']"  @click="readNotification(list.uuid)"> {{list.notificationText.substr(0,70)}}...</div>
                                        <div :class="['notification_date']"> {{list.createdAt.substr(0,10)}}</div>                                
                                    </div>
                                </li>
                            </ul>
                        </perfect-scrollbar>
                            <router-link to="user-notification" class="up_seeall_notification_btn">See All Notifications</router-link>
                        </div>
                    </li>
                    
                    <li class="dropdown user user-menu">
                        <div class="up_user_dropdown_toggle" data-toggle="dropdown" aria-expanded="false" @click="customerDropdownToggle('userMenu')">
                            <img :src="customerDetails.avatar === null ? imageUrlFrontEnd + 'dist/img/profile-icon.png': customerDetails.avatar" alt=""> {{ user.userName }}
                            <small><i class="pi pi-angle-down p-ml-2"></i></small>
                        </div>
                        <div :class="['up_user_dropdown', customerDropdownShow.usermenu ? 'up_user_dropdown_show' : '']">
                            <ul class="up_user_dropdown_menu" v-show="customerDropdownShow.usermenu">
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/getProfile">My Profile</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/change-password">Change Password</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/scanning">Accessibility Scanning</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/privacyform-leads">Privacy Form Leads</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/support-tickets">Support Ticket</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/website-logs">Scan Logs</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <router-link to="/customer/settings">Settings</router-link>
                                </li>
                                <li @click="customerDropdownToggle('userMenu')">
                                    <a @click="customerLogout()">Sign out</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import customerLogout from '../../../helper/userLogout/customerLogout';
import { userPortalNotification } from '../../../config/appUrls';
import { apiKey, imageUrlFrontEnd } from '../../../config/constant';
import { computed, onBeforeMount, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    name: 'Header',
    data(){
        // const mobileMenu = () =>{
        //     this.showMobileMenu = !this.showMobileMenu;
        // }
          return {
            // showMobileMenu: false,
            // mobileMenu
          }; 
    },
    methods(){
        
    },
    setup(){
        let store = useStore();

        let isDashboardNow = computed(() => store.getters.fullHeaderStatus);
        let customerDetails = computed(() => store.getters.customerDetails);

        let user = reactive({
            userName: 'PROFILE'
        });

        let customerDropdownShow = reactive({
            notification: false,
            usermenu: false,
            mobileMenu: false
        });

        const customerDropdownToggle = (type) =>{
            if (type === "userMenu") {
                customerDropdownShow.usermenu = !customerDropdownShow.usermenu;
            } else {
                customerDropdownShow.notification = !customerDropdownShow.notification;
            }
        }

        const notification = reactive({
            data: null,
            count: null,
            unread: null,
        })

        document.addEventListener("click", function(e){
            
            if(customerDropdownShow.usermenu === true){
                if(e.target.closest('.up_user_dropdown_toggle, .up_user_dropdown') === null){
                    customerDropdownShow.usermenu = false;
                }
            }
            if (customerDropdownShow.notification === true) {
                if(e.target.closest('.up_bell_icon, .up_notification_box') === null){
                    customerDropdownShow.notification = false;
                }
            }
            if (customerDropdownShow.showMobileMenu === true) {
                if(e.target.closest('.mobi_menu_toggler, .iconActive') === null){
                    customerDropdownShow.showMobileMenu = false;
                }
            }
        });

        onBeforeMount(()=>{
            user.userName = (JSON.parse(localStorage.getItem('customer-details'))).name;
        });

        const fetchNotification = async() =>{
            try{
                const response = await axios.get( userPortalNotification + '/user', {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                // console.log(response.data.data.notifications)
                notification.data = response.data.data.notifications;
                notification.count = response.data.data.total;
                notification.unread = response.data.data.unreadCount;
            } catch(err){
                if(err.response && err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const readNotification = async(uuid) => {
            try {
                notification.unread--;
                await axios(userPortalNotification, {
                    method: 'PATCH',
                    data: {uuid},
                    headers: {'apiKey': apiKey, 'token': store.getters.adminAuthToken}
                })
            } catch (error) {
                console.log(error);
            }
        }

        const closeOnClick = () => {

        }

        const pollNotifications = async() =>{
            try{
                const response = await axios.get( userPortalNotification + '/user', {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                notification.data = response.data.data.notifications;
                notification.count = response.data.data.total;
                if(notification.unread !== response.data.data.unreadCount)
                    notification.unread = response.data.data.unreadCount;
                setTimeout(pollNotifications, 5000);

            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        onMounted(() => {
            setTimeout(pollNotifications, 3000);
        });

        const mobileMenu = () =>{
            customerDropdownShow.showMobileMenu = !customerDropdownShow.showMobileMenu;
        }

        return{
            customerDetails,
            notification,
            isDashboardNow,
            imageUrlFrontEnd,
            customerDropdownShow,
            user,
            customerDropdownToggle,
            customerLogout,
            closeOnClick,
            readNotification,
            fetchNotification,
            mobileMenu
        }
    },
    
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/component/userportal/common/Header.css';
</style>